import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';

import { CommonModule } from '@angular/common';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatRadioModule} from '@angular/material/radio';
import { TenantSelectorDialogComponent } from './header/tenant-selector-dialog/tenant-selector-dialog.component';
import { BcWwsWebApiModule } from 'bcWwsWebApi';
import { ProgressSpinnerModule } from '../progress-spinner/progress-spinner.module';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
  declarations: [
    HeaderComponent,
    TenantSelectorDialogComponent
  ],
  imports: [
    BcWwsWebApiModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatButtonModule,
    CommonModule,
    MatGridListModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    RouterModule,
    MatSidenavModule,
    MatListModule,
    MatDialogModule,
    MatRadioModule,
    ProgressSpinnerModule,
    MatCardModule,
    MatExpansionModule 
  ],
  exports:[
    HeaderComponent,
  ],
  
  providers: [
   
  ]

})
export class BcHeaderModule { }
