import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProgressSpinnerDialogComponent } from './progress-spinner-dialog/progress-spinner-dialog.component';
import { ProgressSpinnerService } from './progress-spinner-service';


@NgModule({
  declarations: [
    ProgressSpinnerDialogComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
  ],
  providers:[
    ProgressSpinnerService
  ],
  exports:[
    ProgressSpinnerDialogComponent
  ]

})
export class ProgressSpinnerModule { }
