import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BcSnackBarService } from "./bc-snackbar-service";

@NgModule({
    declarations: [
    ],
    imports: [
      CommonModule,
      MatSnackBarModule,
    ],
    providers:[
      BcSnackBarService
    ]
  
  })
  export class BcSnackbarModule { }