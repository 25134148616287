
  /**
 * @description
 *
 * 'keyuser', Admin
 * 'service-support-user', Benutzer
 * 'keyuser-manage', Dinah/Jana
 * 'service-support-admin', Service Admins
 * 
 *
 * 
 *
 */
export const exsistingRoles = {
    KeyUser: 'keyuser', //Admin
    ServiceSupportUser: 'service-support-user', //Benutzer
    KeyUserManage: 'keyuser-manage', //Dinah/Jana
    ServiceSupportAdmin: 'service-support-admin',//Service Admins
  }

export class RolePredictions {
    static AnyRole : RolePredictFunction = (x : Array<string>, y: Array<string>) => x.some(element => y.includes(element));
    static AllRoles : RolePredictFunction = (x : Array<string>, y: Array<string>) => x.every(element => y.includes(element));
}

export type RolePredictFunction = (requiredRoles: Array<string>, currentRoles: Array<string>) => boolean;