import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { RolePredictFunction } from './existing-roles';

@Injectable()
export class RoleGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    //state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.authService.hasRoles(route.data['roles'] as Array<string>, route.data['rolesPrediction'] as RolePredictFunction).pipe(
    map((isRoleAuthenticated: boolean) => {
      if(isRoleAuthenticated){
        return true;
      }
      
      this.router.navigateByUrl('/common/noright');
      return false;
    }),
    tap(x => console.log("RoleGuard"+x))
    );
  }
}
