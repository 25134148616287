import { BcWwsWebApiModule, Configuration } from 'bcWwsWebApi';
import { NgModule, inject } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BcAuthCoreModule, BcFooterModule, BcHeaderModule, BcSnackbarModule, TenantInterceptor } from 'bcSharedComponents';
import { AuthConfig, OAuthModuleConfig } from 'angular-oauth2-oidc';
import {environment} from "../environments/environment";

import { DGUVProtocolComponent } from './dguv-protocol/dguv-protocol-component/dguv-protocol.component';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import { KontaktComponent } from './kontakt/kontakt-component/kontakt.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { SerialnumberHelpComponent } from './serialnumber-help/serialnumber-help.component';
import { HerstellerserviceComponent } from './herstellerservice/herstellerservice-component/herstellerservice.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';

import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { VorortstoerfallComponent } from './vorortstoerfall/vorortstoerfall component/vorortstoerfall.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SupportComponent } from './support/support-component/support.component';
import { LocoDialogComponent } from './support/loco-dialog/loco-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BROWSERS, DeviceDetectorService } from 'ngx-device-detector';

@NgModule({
  declarations: [
    AppComponent,
    DGUVProtocolComponent,
    KontaktComponent,
    SerialnumberHelpComponent,
    HerstellerserviceComponent,
    VorortstoerfallComponent,
    SupportComponent,
    LocoDialogComponent,
],
  imports: [

    BcAuthCoreModule.forRoot(() => {
      const deviceDetectorService = inject(DeviceDetectorService);
      const supportsSilentRefresh = !(deviceDetectorService.isTablet() || deviceDetectorService.isMobile() || deviceDetectorService.browser === BROWSERS.SAFARI);
      const authConfig: AuthConfig = {
          postLogoutRedirectUri: window.location.origin,
          issuer: environment.keycloakUrl + "/realms/" + environment.realm,
          clientId: environment.clientId,
          responseType: 'code',
          redirectUri: window.location.origin + '/index.html',
          silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
          scope: 'openid profile email roles', // Ask offline_access to support refresh token refreshes
          useSilentRefresh: supportsSilentRefresh, // Needed for Code Flow to suggest using iframe-based refreshes
          silentRefreshTimeout: 5000, // For faster testing
          timeoutFactor: 0.75, // For faster testing
          sessionChecksEnabled: true,
          sessionCheckIntervall: 5000,
          showDebugInformation: !environment.production, // Also requires enabling "Verbose" level in devtools
          clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
          nonceStateSeparator: 'semicolon' // Real semicolon gets mangled by IdentityServer's URI encoding
      };
      return authConfig;
  },
  () => {
      const authModuleConfig: OAuthModuleConfig = {
          resourceServer: {
              allowedUrls: [environment.restApiUrl],
              sendAccessToken: true,
          }
      };
      return authModuleConfig;
  },
  () => {
    const tenantInterceptor: TenantInterceptor =  new TenantInterceptor()
    tenantInterceptor.interceptRoutes = [environment.restApiUrl];
    return tenantInterceptor;
  }
),
    BcWwsWebApiModule.forRoot(() => {
      const config = new Configuration();
      config.basePath = environment.restApiUrl;
      return config;
  }),
    BcFooterModule,
    BcHeaderModule,
    BcSnackbarModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatButtonModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatExpansionModule,
    MatDividerModule,
    MatListModule,
    MatTableModule,
    MatStepperModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatDialogModule,
  ],
  providers: [
  ],
  bootstrap: [AppComponent],
  
})
export class AppModule { }
