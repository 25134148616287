import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root',
})
export class BcSnackBarService {
  
  constructor(
    private snackBar: MatSnackBar,
  ) { 
    
  }

  private addCustomStyles(): void {
    const customStyles = `
      .custom-snackbar {
        white-space: pre-line;
      }
    `;
    const styleElement = document.createElement('style');
    styleElement.appendChild(document.createTextNode(customStyles));
    document.head.appendChild(styleElement);
  }

  public show(message: string, action: string = "Schließen", duration: number = 5000){
    this.addCustomStyles();

    const config = new MatSnackBarConfig();
    config.duration = duration;
    config.verticalPosition = 'top';
    config.horizontalPosition = 'center';
    config.panelClass = ['custom-snackbar'];

    this.snackBar.open(message, action, config);
  }
}
