import { HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class TenantInterceptor implements HttpInterceptor {
 
  public interceptRoutes : string[] = new Array<string>;

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    
    const url = req.url;
    const shouldIntercept = this.interceptRoutes.some(route => url.includes(route));

    if (shouldIntercept) {
      const xtenant = localStorage.getItem('x-tenant') as string;

      if(!!xtenant){
        const authReq = req.clone({
          headers: req.headers.set('x-tenant', xtenant)
        });
        return next.handle(authReq);
      }
    }

    return next.handle(req);
    
  }
}