import { Component, ViewEncapsulation } from '@angular/core';
import { ProgressSpinnerService } from '../progress-spinner-service';

@Component({
  selector: 'app-spinner',
  templateUrl: './progress-spinner-dialog.component.html',
  styleUrls: ['./progress-spinner-dialog.component.sass'],
  //encapsulation: ViewEncapsulation.ShadowDom
})
export class ProgressSpinnerDialogComponent {

  constructor(public loader: ProgressSpinnerService) { }

}
