import { RolePredictFunction } from "../../bc-auth/existing-roles";

export interface IMenuItem {
    routerLink?: string[],
    displayName: string,
    requiredRoles: string[],
    rolesPredictionFunc?: RolePredictFunction,
    visibility?: MenuItemVisibility,
    subItems?: IMenuItem[],
}

export enum MenuItemVisibility {
    Always,
    AfterLogin,
    BeforeLogin
}

export interface IApplicationItem {
    url: string,
    displayName: string,
    icon: string
}
