import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { authAppInitializerFactory } from './auth-app-initializer.factory';
import { AuthGuardWithForcedLogin } from './auth-guard-with-forced-login.service';
import { AuthGuard } from './auth-guard.service';
import { AuthService } from './auth.service';
import { RoleGuard } from './role-guard.service';
import { HttpClientModule } from '@angular/common/http';
import { BcWwsWebApiModule } from 'bcWwsWebApi';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TenantInterceptor } from './tenant-Interceptor';



// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  imports: [
    OAuthModule.forRoot(),
    HttpClientModule,
    BcWwsWebApiModule
  ],
  providers: [
    AuthService,
    AuthGuard,
    AuthGuardWithForcedLogin,
    RoleGuard,
  ],
})
export class BcAuthCoreModule {
  static forRoot(authConfigFactory: () => AuthConfig, oAuthModuleConfig: () => OAuthModuleConfig, tenantInterceptorFac: () => TenantInterceptor): ModuleWithProviders<BcAuthCoreModule> {
    return {
      ngModule: BcAuthCoreModule,
      providers: [
        { provide: APP_INITIALIZER, useFactory: authAppInitializerFactory, deps: [AuthService], multi: true },
        { provide: AuthConfig, useFactory: authConfigFactory },
        { provide: OAuthModuleConfig, useFactory: oAuthModuleConfig },
        { provide: OAuthStorage, useFactory: storageFactory },
        { provide: HTTP_INTERCEPTORS, useFactory: tenantInterceptorFac, multi: true }
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: BcAuthCoreModule) {
    if (parentModule) {
      throw new Error('BcAuthCoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
