<app-spinner></app-spinner>
<mat-drawer-container *ngIf="(this.hasToBeLoggedIn ? this.isLoggedIn : true)" autosize hasBackdrop = true>
  <mat-drawer #drawer class="sidenav" [autoFocus]="false" mode="over" >
    <mat-toolbar class="open-menu-toolbar" color="primary">
      <span translate="no">Menü</span>
      <div class="spacer"></div>
      <button mat-icon-button (click)="drawer.close()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
    <mat-nav-list class="tabs" *ngFor="let menuItem of menuItems" (click)="drawer.close()">
        <mat-list-item *ngIf="(checkRoles(menuItem) | async) && !(hasSubItems(menuItem) | async)" [routerLink]="menuItem.routerLink" translate="no">{{menuItem.displayName}}</mat-list-item>
        <mat-expansion-panel [class.mat-elevation-z0]="true" *ngIf="(checkRoles(menuItem) | async) && (hasSubItems(menuItem) | async)">
          <mat-expansion-panel-header>
            <mat-panel-title>{{menuItem.displayName}}</mat-panel-title>
        </mat-expansion-panel-header>
          <mat-nav-list *ngFor="let subitem of menuItem.subItems">
            <button mat-menu-item *ngIf="(checkRoles(subitem) | async)" [routerLink]="subitem.routerLink" > {{subitem.displayName}} </button>
        </mat-nav-list>
      </mat-expansion-panel>
    </mat-nav-list>
  </mat-drawer>
<header>
  <section>
  <div class="upperheader">
  <div >
    <img class="img" [src]="imagePath">
  </div>
  <div class="spacer"></div>
  <div>
    <button  mat-button *ngIf="this.isLoggedIn" (click)="updateApplications()" [matMenuTriggerFor]="applicationMenu">
      <mat-icon>apps</mat-icon>
    </button>
    <mat-menu #applicationMenu="matMenu">
      <div *ngFor="let item of applicationItems">
        <button mat-menu-item (click)="navigateTo(item.url!)">
          <mat-icon class="icon-display material-symbols-outlined" fontIcon="{{item.icon}}"></mat-icon>
          <span>{{item.displayName}}</span>
        </button>
      </div>
    </mat-menu>
    <button mat-button color="accent" *ngIf="isLoggedIn" [matMenuTriggerFor]="loginMenu"><mat-icon>account_circle</mat-icon><span class="show600">{{username}} {{tenant}}</span></button>
    <button  mat-button *ngIf="!isLoggedIn" [matMenuTriggerFor]="loginMenu"><mat-icon>account_circle</mat-icon></button>
    <mat-menu #loginMenu="matMenu">
      <button *ngIf="!isLoggedIn" mat-menu-item (click)="login()">Login</button>
      <button *ngIf="isLoggedIn" mat-menu-item (click)="navigateToVerwalten()">Mein Zugang</button>
      <button *ngIf="isLoggedIn && (hasMultipleTennants$ | async)" mat-menu-item (click)="setTenant()">Mandant wechseln</button>
      <button *ngIf="isLoggedIn" mat-menu-item (click)="logout()">Logout</button>
    </mat-menu>
    <button mat-button (click)="darkModeClick()"><mat-icon *ngIf="!darkMode">dark_mode</mat-icon><mat-icon *ngIf="darkMode">light_mode</mat-icon></button>
  </div>
</div>
  <mat-toolbar color="primary">
      <button mat-icon-button class="menu-icon hidden600" (click)="drawer.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <div class="show600" *ngFor="let menuItem of menuItems" >
        <a mat-button *ngIf="(checkRoles(menuItem) | async) && !(hasSubItems(menuItem) | async)" [routerLink]="menuItem.routerLink" translate="no">{{menuItem.displayName}}</a>
        <a mat-button *ngIf="(checkRoles(menuItem) | async) && (hasSubItems(menuItem) | async)" [matMenuTriggerFor]="submenu" translate="no">{{menuItem.displayName}}</a>
        <mat-menu #submenu="matMenu">
          <div *ngFor="let subitem of menuItem.subItems">
            <button mat-menu-item *ngIf="(checkRoles(subitem) | async) && !(checkRoute(subitem) | async) "[routerLink]="subitem.routerLink">{{subitem.displayName}}</button>
           <!-- TEMPORÄR FÜR "COMING SOON" -->
            <button class="disablé" mat-menu-item *ngIf="(checkRoles(subitem) | async) && (checkRoute(subitem) | async)" ><a class="opaque">{{subitem.displayName}}</a><a class="greenwrapped">coming soon</a></button>
          <!-- WIEDER ENTFERNEN -->
          </div>
        </mat-menu>
    </div>
  </mat-toolbar>
  <mat-card class="deliveryStop" *ngIf="isTenantLocked$ | async"> 
    <div *ngIf="this.tenantLockedMessage$ | async as message">
    <mat-card-header>
      <mat-card-title>{{message?.header}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>{{message?.detail}}</p>
    </mat-card-content>
  </div>
  </mat-card>
</section>
</header>
<ng-content></ng-content>
</mat-drawer-container>

