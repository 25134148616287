<footer >
  <section>
    <div class="content container">
      <div class="col">
        <mat-list class="my-list-1">
          <mat-list-item><span class="bigger">UNTERNEHMEN</span></mat-list-item>
          <a class="navigateable" mat-list-item href="https://www.bluechip.de/karriere-bei-bluechip"><span class="smaller">AUSBILDUNG & KARRIERE</span></a>
          <a class="navigateable" mat-list-item href="https://www.bluechip.de/kontaktformular"><span class="smaller">KONTAKTFORMULAR</span></a>
          <a class="navigateable" mat-list-item href="https://www.bluechip.de/ueber-bluechip"><span class="smaller">ÜBER BLUECHIP</span></a>
          <a class="navigateable" mat-list-item href="https://www.bluechip.de/haendler/"><span class="smaller">FACHHÄNDLERSUCHE</span></a>
        </mat-list>
      </div>
      <div class="col">
        <mat-list class="my-list-1">
          <mat-list-item><span class="bigger">RECHTLICHES</span></mat-list-item>
          <a class="navigateable" mat-list-item (click)="navServiceVereinbarungen()" ><span class="smaller">SERVICEVEREINBARUNGEN</span></a>
          <a class="navigateable" mat-list-item href="/dataprotection"><span class="smaller">DATENSCHUTZ</span></a>
          <a class="navigateable" mat-list-item href="https://www.bluechip.de/agb"><span class="smaller">AGB</span></a>
          <a class="navigateable" mat-list-item href="https://www.bluechip.de/impressum"><span class="smaller">IMPRESSUM</span></a>
        </mat-list>
      </div>
      <div class="col">
        <mat-list class="my-list-1">
          <mat-list-item><span class="bigger">KONTAKT</span></mat-list-item>
          <mat-list-item><span class="smaller">bluechip Computer AG</span></mat-list-item>
          <mat-list-item><span class="smaller">Geschwister-Scholl-Str. 11a</span></mat-list-item>
          <mat-list-item><span class="smaller">04610 Meuselwitz</span></mat-list-item>
          <mat-list-item></mat-list-item>
          <mat-list-item><span class="smaller">TEL: +49 / 3448 / 755-0</span></mat-list-item>
          <mat-list-item><span class="smaller">Fax: +49 / 3448 / 755-105</span></mat-list-item>
          <mat-list-item><span class="smaller">E-Mail: <a href="mailto:info@bluechip.de">info&#64;bluechip.de</a></span></mat-list-item>
        </mat-list>
      </div>
    </div>
  </section>
</footer>
