import { Component } from '@angular/core';
import { Destroyer, IApplicationItem, IMenuItem, MenuItemVisibility, RolePredictions, exsistingRoles } from 'bcSharedComponents';
import { StatisticsService } from 'bcWwsWebApi';
import { Observable, from, map, takeUntil } from 'rxjs';
import { environment } from '../environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent extends Destroyer{
  constructor(private statisticsService: StatisticsService) 
  {
    super();
    this.hideHeaderAndFooter = window.location.href.includes(environment.ectServiceUrl);
  }
  title = 'bcService';
  hideHeaderAndFooter: boolean = true;
  menuItems: IMenuItem[] = [
    {displayName: 'Startseite', routerLink: ['suche'], requiredRoles: [] }, 
    {displayName: 'Kontakt', routerLink: ['kontakt'], requiredRoles: []},
    {displayName: 'Servicevereinbarungen', routerLink: ['servicevereinbarungen'], requiredRoles: [exsistingRoles.ServiceSupportAdmin, exsistingRoles.ServiceSupportUser], rolesPredictionFunc: RolePredictions.AnyRole , visibility: MenuItemVisibility.AfterLogin},
    {displayName: "Admin", requiredRoles: [exsistingRoles.ServiceSupportAdmin], subItems: [
      {displayName: 'Rma Admin', routerLink: ['admin'], requiredRoles: [exsistingRoles.ServiceSupportAdmin]},
      {displayName: 'Vorabtausch Admin', routerLink: ['vorabtausch','admin-table'], requiredRoles: [exsistingRoles.ServiceSupportAdmin]},
      {displayName: 'Rma Antrag Admin', routerLink: ['rma','rmaAdmin'], requiredRoles: [exsistingRoles.ServiceSupportAdmin]},
      {displayName: 'Vorabtausch Antrag Admin', routerLink: ['vorabtausch','vorabtauschAdmin'], requiredRoles: [exsistingRoles.ServiceSupportAdmin]},
    ]},
  ];
  applicationItems$: Observable<IApplicationItem[]> = from([]);
  
  ngOnInit(): void {
    this.applicationItems$ = this.statisticsService
                              .apiStatisticsGetApplicationsGet()
                              .pipe(takeUntil(this.destroy$), map(value => value.map( x => ({ displayName: x.displayString, url: x.value, icon: x.icon } as IApplicationItem))));
  }

}
