<ng-container >
  <bc-header *ngIf="!hideHeaderAndFooter" [menuItems]="menuItems" [applicationItems$]="applicationItems$" [hasToBeLoggedIn]="false">
  </bc-header>
  <main>
    <section>
      <router-outlet></router-outlet>
    </section>
  </main>
  <bc-footer *ngIf="!hideHeaderAndFooter"></bc-footer>
</ng-container>
