import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { TennantInfoDto } from 'bcWwsWebApi';

@Component({
  selector: 'lib-tenant-selector-dialog',
  templateUrl: './tenant-selector-dialog.component.html',
  styleUrls: ['./tenant-selector-dialog.component.css'],
})
export class TenantSelectorDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<string>,
    @Inject(MAT_DIALOG_DATA) public tenants: TennantInfoDto[]
  ) {}

  ngOnInit(): void {}

  radioChange(event: MatRadioChange) {
    this.dialogRef.close({ result: event.value });
  }
}
